<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-icon large>
          {{ icon }}
        </v-icon>
        ข้อมูลใบแจ้งหนี้พิเศษ
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          color="secondary"
          single-line
          hide-details
        />
      </v-card-actions>
      <v-card-text>
        <!-- <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          @click="exportToExcel()"
        >
          <v-icon> mdi-microsoft-excel </v-icon>
          Export to Excel
        </v-btn> -->
        <v-data-table
          :headers="headers"
          :items="customerSpecialBillDatas"
          :search.sync="search"
          :options.sync="options"
          :footer-props="{ itemsPerPageOptions: [10, 50, 100, 1000] }"
          :loading="loading"
          loading-text="Loading..."
          :server-items-length="totalCustomerSpecialBillDatas"
          class="elevation-1"
        >
          <!-- <template v-slot:[`item.address`]="{ item }">
            <span>
              {{
                `${item.address} ${
                  item.subdistrict.district.province.id == 1 ? "" : "ต."
                }${item.subdistrict.name_in_thai} ${
                  item.subdistrict.district.province.id == 1 ? "" : "อ."
                }${item.subdistrict.district.name_in_thai} ${
                  item.subdistrict.district.province.id == 1 ? "" : "จังหวัด"
                }${item.subdistrict.district.province.name_in_thai} ${
                  item.subdistrict.zip_code
                }`
              }}
            </span>
          </template> -->
          <!-- <template v-slot:[`item.specialBill`]="{ item }">
            <v-icon v-if="item.specialBill" small color="green">
              mdi-check-bold
            </v-icon>
          </template> -->
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              small
              color="secondary"
              class="ma-2"
              outlined
              :to="'/customer/' + item.customer.id + '/show'"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
              Detail
            </v-btn>
            <!-- <v-btn
              small
              color="primary"
              class="ma-2"
              outlined
              :to="'/customer/' + item.id + '/edit'"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
              Edit
            </v-btn> -->
            <!-- <v-btn
            small
            color="primary"
            class="ma-2"
            outlined
            :to="'/customer/' + item.id + '/delete'"
          >
            <v-icon>
              mdi-delete
            </v-icon>
            Delete
          </v-btn> -->
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mdiReceiptTextOutline } from '@mdi/js';

moment.locale("th");

export default {
  name: "CustomerSpecialBillDataList",
  props: {},
  data() {
    return {
      icon: mdiReceiptTextOutline,
      search: "",
      totalCustomerSpecialBillDatas: 0,
      options: {},
      customerSpecialBillDatas: [],
      loading: false,
      headers: [
        {
          text: "CA",
          value: "CA",
          sortable: false,
          align: "center",
        },
        {
          text: "ชื่อลูกค้า",
          value: "customer.name",
          sortable: false,
          align: "center",
        },
        {
          text: "ประเภทลูกค้า",
          value: "customer.customerCategory.name",
          sortable: false,
          align: "center",
        },
        {
          text: "ศูนย์บริการลูกค้า",
          value: "customer.officeCentre.name",
          sortable: false,
          align: "center",
        },
        {
          text: "ส่วนบริการลูกค้า",
          value: "customer.officeCentre.officeSector.name",
          sortable: false,
          align: "center",
        },
        {
          text: "",
          value: "action",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromAPI();
      },
      deep: true,
    },
    search: {
      handler() {
        this.getDataFromAPI();
      },
      deep: true,
    },
  },
  created() {

  },
  methods: {
    formatDate(date) {
      return moment(date).format("LLL");
    },
    getDataFromAPI() {
      this.loading = true;
      const queryParams = {
        params: {
          search: this.search,
          page: this.options.page,
          per_page: this.options.itemsPerPage,
        },
      };
      axios
        .get("/customer-special-bill-data", queryParams)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.customerSpecialBillDatas = response.data.data;
          this.totalCustomerSpecialBillDatas = response.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // exportToExcel() {
    //   this.loading = true;
    //   const queryParams = {
    //     params: {
    //       search: this.search,
    //     },
    //     responseType: "arraybuffer",
    //   };
    //   axios
    //     .get("/export-to-excel/customer-use-service", queryParams)
    //     .then((response) => {
    //       // console.log(response);
    //       let blob = new Blob([response.data], {
    //         type: response.headers["content-type"],
    //       });
    //       let link = document.createElement("a");
    //       link.href = window.URL.createObjectURL(blob);
    //       // console.log(response.headers);
    //       link.download = "BCRM_บริการที่ลูกค้าใช้งาน.xlsx";
    //       link.click();
    //       this.loading = false;
    //     })
    //     .catch(() => {
    //       this.loading = false;
    //     });
    // },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
